import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Flex } from "@chakra-ui/react";

const Header = lazy(() => import("./Header/Header"));
const MenuBar = lazy(() => import("./MenuBar/MenuBar"));
const Layout: React.FC = () => {
  return (
    <Flex direction="column" minH="100vh" bgColor="#EDEFF4">
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
      </Suspense>

      <Flex p="4" px="9" pb="28" flexDirection="column">
        {/* <Flex alignItems="center" justifyContent="flex-end" mt="4">
          <Breadcrumb />
        </Flex> */}
        <Outlet />
      </Flex>

      <Suspense fallback={<div>Loading...</div>}>
        <MenuBar />
      </Suspense>
    </Flex>
  );
};

export default Layout;
