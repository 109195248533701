import React from "react";
import { useParams } from "react-router-dom";

// import { TimeIcon } from "@chakra-ui/icons";
import { Box, Skeleton, Text } from "@chakra-ui/react";

import { useGetMeQuery } from "redux/services/apiService";
import { useGetMenusQuery } from "redux/services/menu";

import GoogleDataStudio from "components/GoogleDataStudio/GoogleDataStudio";
import Tableau from "components/Tableau/Tableau";
// import { today } from "utils/constants";
import { decode } from "utils/hash";

type Props = {};
const DynamicDashboard: React.FC<Props> = (): JSX.Element => {
  const params = useParams();
  const { data: menus = [] } = useGetMenusQuery();

  const getDesc = (param) => {
    var desc = "";
    menus.some((m) =>
      m.children.some((c) => {
        if (c.name.replaceAll(" ", "-") === param) {
          desc = c.description;
        }
        return desc;
      })
    );
    return desc;
  };
  const { isLoading } = useGetMeQuery();

  const RenderEmbed = () => {
    const dashboardUrl = decodeURIComponent(decode(params.source));

    if(dashboardUrl.includes("tableau")) {
      return (
        <>
          <Tableau
            url={dashboardUrl}
            containerId={params.source}
          />
        </>
      )
    }
    else if(dashboardUrl.includes("datastudio")) {
      return (
        <>
          <GoogleDataStudio source={dashboardUrl} />
        </>
      )
    } else {
      return (
        <>
          <iframe
            src={dashboardUrl}
            title="media social embeded"
            style={{ overflow: "hidden", height: "900px", width: "100%" }}
            height="900px"
            width="100%"
          />
        </>
      )
    }

  }

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box mt="4">
        {params?.source !== "bnVsbA==" ? (
          <Box>
            <RenderEmbed />
            {getDesc(params.menu) && getDesc(params.menu) !== null ? (
              <>
                <Text fontSize="15" fontWeight="bold" color="#C0392B" mt="2" mb="0">
                  Deskripsi:
                </Text>
                <Text fontSize="15" fontWeight="bold" color="#C0392B" mt="0">
                  {getDesc(params.menu)}
                </Text>
              </>
            ) : null}
          </Box>
        ) : (
          <Text fontSize="5xl" fontWeight="bold" color="#C0392B" textAlign="center" mt="20">
            Coming Soon!
          </Text>
        )}
      </Box>
    </Skeleton>
  );
};

export default DynamicDashboard;
