import { lazy } from "react";

const Dashboard = lazy(() => import("pages/dashboard"));
const Cctv = lazy(() => import("pages/cctv/CctvPage"));
const ListUser = lazy(() => import("pages/setting/UserSetting"));
const CreateUser = lazy(() => import("pages/setting/UserSetting/CreateUser"));
const EditUser = lazy(() => import("pages/setting/UserSetting/EditUser"));

const ListRole = lazy(() =>
  import("pages/setting/RoleSetting").then((r) => ({ default: r.RoleSetting }))
);
const CreateRole = lazy(() =>
  import("pages/setting/RoleSetting").then((r) => ({ default: r.CreateRole }))
);
const EditRole = lazy(() =>
  import("pages/setting/RoleSetting").then((r) => ({ default: r.EditRole }))
);

const ListMenu = lazy(() => import("pages/setting/MenuSetting/MenuSetting"));
const CreateMenu = lazy(() => import("pages/setting/MenuSetting/CreateMenu"));
const EditMenu = lazy(() => import("pages/setting/MenuSetting/EditMenu"));
const SortMenu = lazy(() => import ("pages/setting/MenuSetting/SortMenu"))

const ListPermission = lazy(
  () => import("pages/setting/PermissionSetting/PermissionSetting")
);
const CreatePermission = lazy(
  () => import("pages/setting/PermissionSetting/CreatePermission")
);
const EditPermission = lazy(
  () => import("pages/setting/PermissionSetting/EditPermission")
);

const EditConfig = lazy(() => import("pages/setting/ConfigSetting/EditConfig"));
export {
  Cctv,
  CreateMenu,
  CreatePermission,
  CreateRole,
  CreateUser,
  Dashboard,
  EditConfig,
  EditMenu,
  EditPermission,
  EditRole,
  EditUser,
  ListMenu,
  ListPermission,
  ListRole,
  ListUser,
  SortMenu,
};
